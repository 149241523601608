// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment_event(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "BroadcastBuyerNeedCreatedAMLEvent",
              "BuyerNeedEditedAMLEvent",
              "QuietBuyerNeedBroadcastedAMLEvent",
              "QuietBuyerNeedCreatedAMLEvent"
            ]);
}

var fragmentConverter = {"__root":{"source_agent":{"f":""},"event_QuietBuyerNeedCreatedAMLEvent_matches_edges_node_source_client":{"f":""},"event_QuietBuyerNeedCreatedAMLEvent_matches_edges_node_source":{"f":""},"event_QuietBuyerNeedBroadcastedAMLEvent_newMatches_edges_node_source_client":{"f":""},"event_QuietBuyerNeedBroadcastedAMLEvent_newMatches_edges_node_source":{"f":""},"event_QuietBuyerNeedBroadcastedAMLEvent_existingMatches_edges_node_source_client":{"f":""},"event_QuietBuyerNeedBroadcastedAMLEvent_existingMatches_edges_node_source":{"f":""},"event_BuyerNeedEditedAMLEvent_newMatches_edges_node_source_client":{"f":""},"event_BuyerNeedEditedAMLEvent_newMatches_edges_node_source":{"f":""},"event_BuyerNeedEditedAMLEvent_existingMatches_edges_node_source_client":{"f":""},"event_BuyerNeedEditedAMLEvent_existingMatches_edges_node_source":{"f":""},"event_BroadcastBuyerNeedCreatedAMLEvent_matches_edges_node_source_client":{"f":""},"event_BroadcastBuyerNeedCreatedAMLEvent_matches_edges_node_source":{"f":""},"event":{"u":"fragment_event"}}};

var fragmentConverterMap = {
  fragment_event: unwrap_fragment_event
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Seller",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "NotificationWidgetTextsClientLabel_client",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientContactsConnection",
              "kind": "LinkedField",
              "name": "contacts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ClientContactsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ClientContactV5",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ClientV5",
          "abstractKey": "__isClientV5"
        }
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "storageKey": null
},
v2 = {
  "kind": "InlineDataFragmentSpread",
  "name": "NotificationWidgetTextsPropertyLocationLabel_property",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "propertyLocation",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "locationDisplay",
                  "storageKey": null
                }
              ],
              "type": "PropertyLocationWithHiddenAddress",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "type": "PropertyLocationWithVisibleAddress",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PropertyV5",
      "abstractKey": "__isPropertyV5"
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MatchForForSalePropertyV5",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ForSalePropertyV5",
        "kind": "LinkedField",
        "name": "source",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ForSalePropertyV5",
  "kind": "LinkedField",
  "name": "source",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MatchForForSalePropertyV5",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MatchForForSalePropertyV5",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "throwOnFieldError": true
  },
  "name": "NotificationWidgetTextsBuyerNeed_aml",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BuyerNeedV5",
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "agent",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationWidgetTextsAgent_agent"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BroadcastBuyerNeedCreatedAMLEventMatchesConnection",
              "kind": "LinkedField",
              "name": "matches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BroadcastBuyerNeedCreatedAMLEventMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "BroadcastBuyerNeedCreatedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BuyerNeedEditedAMLEventExistingMatchesConnection",
              "kind": "LinkedField",
              "name": "existingMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BuyerNeedEditedAMLEventExistingMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BuyerNeedEditedAMLEventNewMatchesConnection",
              "kind": "LinkedField",
              "name": "newMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BuyerNeedEditedAMLEventNewMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "BuyerNeedEditedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuietBuyerNeedBroadcastedAMLEventExistingMatchesConnection",
              "kind": "LinkedField",
              "name": "existingMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuietBuyerNeedBroadcastedAMLEventExistingMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "QuietBuyerNeedBroadcastedAMLEventNewMatchesConnection",
              "kind": "LinkedField",
              "name": "newMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuietBuyerNeedBroadcastedAMLEventNewMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "QuietBuyerNeedBroadcastedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuietBuyerNeedCreatedAMLEventMatchesConnection",
              "kind": "LinkedField",
              "name": "matches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuietBuyerNeedCreatedAMLEventMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "QuietBuyerNeedCreatedAMLEvent",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BuyerNeedAgentMatchLogV5",
  "abstractKey": null
};
})());

var wrap_fragment_event = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment_event = unwrap_fragment_event;
exports.wrap_fragment_event = wrap_fragment_event;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
