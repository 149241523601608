// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment_event(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "RentalPropertyCreatedAMLEvent",
              "RentalPropertyEditedAMLEvent",
              "RentalPropertyRebroadcastedAMLEvent"
            ]);
}

var fragmentConverter = {"__root":{"source_agent":{"f":""},"event_RentalPropertyRebroadcastedAMLEvent_newMatches_edges_node_source_client":{"f":""},"event_RentalPropertyRebroadcastedAMLEvent_existingMatches_edges_node_source_client":{"f":""},"event_RentalPropertyEditedAMLEvent_newMatches_edges_node_source_client":{"f":""},"event_RentalPropertyEditedAMLEvent_existingMatches_edges_node_source_client":{"f":""},"event_RentalPropertyCreatedAMLEvent_matches_edges_node_source_client":{"f":""},"event":{"u":"fragment_event"}}};

var fragmentConverterMap = {
  fragment_event: unwrap_fragment_event
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "RentalNeedV5",
  "kind": "LinkedField",
  "name": "source",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Renter",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "NotificationWidgetTextsClientLabel_client",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ClientContactsConnection",
                  "kind": "LinkedField",
                  "name": "contacts",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ClientContactsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ClientContactV5",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "displayName",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "ClientV5",
              "abstractKey": "__isClientV5"
            }
          ],
          "args": null,
          "argumentDefinitions": ([]/*: any*/)
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MatchForRentalNeedV5",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v1/*: any*/)
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MatchForRentalNeedV5",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "throwOnFieldError": true
  },
  "name": "NotificationWidgetTextsRentalProperty_aml",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalPropertyV5",
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "agent",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationWidgetTextsAgent_agent"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationWidgetTextsAgent_agentId"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RentalPropertyCreatedAMLEventMatchesConnection",
              "kind": "LinkedField",
              "name": "matches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalPropertyCreatedAMLEventMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RentalPropertyCreatedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RentalPropertyEditedAMLEventExistingMatchesConnection",
              "kind": "LinkedField",
              "name": "existingMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalPropertyEditedAMLEventExistingMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RentalPropertyEditedAMLEventNewMatchesConnection",
              "kind": "LinkedField",
              "name": "newMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalPropertyEditedAMLEventNewMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RentalPropertyEditedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RentalPropertyRebroadcastedAMLEventExistingMatchesConnection",
              "kind": "LinkedField",
              "name": "existingMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalPropertyRebroadcastedAMLEventExistingMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RentalPropertyRebroadcastedAMLEventNewMatchesConnection",
              "kind": "LinkedField",
              "name": "newMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalPropertyRebroadcastedAMLEventNewMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RentalPropertyRebroadcastedAMLEvent",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RentalPropertyAgentMatchLogV5",
  "abstractKey": null
};
})());

var wrap_fragment_event = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment_event = unwrap_fragment_event;
exports.wrap_fragment_event = wrap_fragment_event;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
