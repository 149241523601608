// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment_event(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "RentalNeedCreatedAMLEvent",
              "RentalNeedEditedAMLEvent"
            ]);
}

var fragmentConverter = {"__root":{"source_agent":{"f":""},"event_RentalNeedEditedAMLEvent_newMatches_edges_node_source_client":{"f":""},"event_RentalNeedEditedAMLEvent_newMatches_edges_node_source":{"f":""},"event_RentalNeedEditedAMLEvent_existingMatches_edges_node_source_client":{"f":""},"event_RentalNeedEditedAMLEvent_existingMatches_edges_node_source":{"f":""},"event_RentalNeedCreatedAMLEvent_matches_edges_node_source_client":{"f":""},"event_RentalNeedCreatedAMLEvent_matches_edges_node_source":{"f":""},"event":{"u":"fragment_event"}}};

var fragmentConverterMap = {
  fragment_event: unwrap_fragment_event
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Landlord",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "NotificationWidgetTextsClientLabel_client",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientContactsConnection",
              "kind": "LinkedField",
              "name": "contacts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ClientContactsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ClientContactV5",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ClientV5",
          "abstractKey": "__isClientV5"
        }
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "storageKey": null
},
v2 = {
  "kind": "InlineDataFragmentSpread",
  "name": "NotificationWidgetTextsPropertyLocationLabel_property",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "propertyLocation",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "locationDisplay",
                  "storageKey": null
                }
              ],
              "type": "PropertyLocationWithHiddenAddress",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "type": "PropertyLocationWithVisibleAddress",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PropertyV5",
      "abstractKey": "__isPropertyV5"
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "RentalPropertyV5",
  "kind": "LinkedField",
  "name": "source",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "throwOnFieldError": true
  },
  "name": "NotificationWidgetTextsRentalNeed_aml",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalNeedV5",
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "agent",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationWidgetTextsAgent_agent"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RentalNeedCreatedAMLEventMatchesConnection",
              "kind": "LinkedField",
              "name": "matches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalNeedCreatedAMLEventMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MatchForRentalPropertyV5",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RentalPropertyV5",
                          "kind": "LinkedField",
                          "name": "source",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RentalNeedCreatedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RentalNeedEditedAMLEventExistingMatchesConnection",
              "kind": "LinkedField",
              "name": "existingMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalNeedEditedAMLEventExistingMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MatchForRentalPropertyV5",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RentalNeedEditedAMLEventNewMatchesConnection",
              "kind": "LinkedField",
              "name": "newMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalNeedEditedAMLEventNewMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MatchForRentalPropertyV5",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RentalNeedEditedAMLEvent",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RentalNeedAgentMatchLogV5",
  "abstractKey": null
};
})());

var wrap_fragment_event = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment_event = unwrap_fragment_event;
exports.wrap_fragment_event = wrap_fragment_event;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
