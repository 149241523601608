// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "AnonymousForSalePropertyAgent",
              "VisibleForSalePropertyAgent"
            ]);
}

var fragmentConverter = {"__root":{"VisibleForSalePropertyAgent_agent":{"f":""},"":{"u":"fragment"}}};

var fragmentConverterMap = {
  fragment: unwrap_fragment
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationWidgetTextsForSale_agent",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "agent",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationWidgetTextsAgent_agent"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "VisibleForSalePropertyAgent",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "AnonymousForSalePropertyAgent",
      "abstractKey": null
    }
  ],
  "type": "ForSalePropertyAgent",
  "abstractKey": "__isForSalePropertyAgent"
};
})());

var wrap_fragment = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment = unwrap_fragment;
exports.wrap_fragment = wrap_fragment;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
