// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "AgentMatchLogNotification",
              "UnfinishedDraftNotification",
              "UpcomingShowingNotification"
            ]);
}

function unwrap_fragment_AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["VisibleForSalePropertyAgent"]);
}

function unwrap_fragment_AgentMatchLogNotification_agentMatchLogByType(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "BuyerNeedAgentMatchLogV5",
              "ForSalePropertyAgentMatchLogV5",
              "RentalNeedAgentMatchLogV5",
              "RentalPropertyAgentMatchLogV5"
            ]);
}

function unwrap_fragment_UpcomingShowingNotification_showing_property_agentV2(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["VisibleForSalePropertyAgent"]);
}

var fragmentConverter = {"__root":{"UpcomingShowingNotification_showing_property_agentV2_VisibleForSalePropertyAgent_agent":{"f":""},"UpcomingShowingNotification_showing_property_agentV2":{"u":"fragment_UpcomingShowingNotification_showing_property_agentV2"},"UpcomingShowingNotification_showing":{"f":""},"AgentMatchLogNotification_agentMatchLogByType_RentalPropertyAgentMatchLogV5_source_agent":{"f":""},"AgentMatchLogNotification_agentMatchLogByType_RentalPropertyAgentMatchLogV5":{"f":""},"AgentMatchLogNotification_agentMatchLogByType_RentalNeedAgentMatchLogV5_source_agent":{"f":""},"AgentMatchLogNotification_agentMatchLogByType_RentalNeedAgentMatchLogV5":{"f":""},"AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2_VisibleForSalePropertyAgent_agent":{"f":""},"AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2":{"u":"fragment_AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2"},"AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5":{"f":""},"AgentMatchLogNotification_agentMatchLogByType_BuyerNeedAgentMatchLogV5_source_agent":{"f":""},"AgentMatchLogNotification_agentMatchLogByType_BuyerNeedAgentMatchLogV5":{"f":""},"AgentMatchLogNotification_agentMatchLogByType":{"u":"fragment_AgentMatchLogNotification_agentMatchLogByType"},"":{"u":"fragment"}}};

var fragmentConverterMap = {
  fragment_AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2: unwrap_fragment_AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2,
  fragment_AgentMatchLogNotification_agentMatchLogByType: unwrap_fragment_AgentMatchLogNotification_agentMatchLogByType,
  fragment_UpcomingShowingNotification_showing_property_agentV2: unwrap_fragment_UpcomingShowingNotification_showing_property_agentV2,
  fragment: unwrap_fragment
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function draftOrigin_decode($$enum) {
  if ($$enum === "NEW" || $$enum === "BROADCAST_QUIET_SELLER" || $$enum === "REBROADCAST") {
    return $$enum;
  }
  
}

function draftOrigin_fromString(str) {
  return draftOrigin_decode(str);
}

var Utils = {
  draftOrigin_decode: draftOrigin_decode,
  draftOrigin_fromString: draftOrigin_fromString
};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Agent",
    "kind": "LinkedField",
    "name": "agent",
    "plural": false,
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AgentProfilePhoto_agent"
      }
    ],
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "agentV2",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "VisibleForSalePropertyAgent",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationWidgetItemSource_notification",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "ShowingV5",
            "kind": "LinkedField",
            "name": "showing",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotificationWidgetTexts_showing"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "concreteType": "ForSalePropertyV5",
                  "kind": "LinkedField",
                  "name": "property",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                "action": "THROW"
              }
            ],
            "storageKey": null
          },
          "action": "THROW"
        }
      ],
      "type": "UpcomingShowingNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "DraftV5",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "origin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "publishedProperty",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DraftPropertyV5",
                "kind": "LinkedField",
                "name": "draftProperty",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Area",
                    "kind": "LinkedField",
                    "name": "userSelectedArea",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        "action": "NONE"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          "action": "THROW"
        }
      ],
      "type": "UnfinishedDraftNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "agentMatchLog",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          "action": "THROW"
        },
        {
          "alias": "agentMatchLogByType",
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "agentMatchLog",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotificationWidgetTextsBuyerNeed_aml"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BuyerNeedV5",
                  "kind": "LinkedField",
                  "name": "source",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "BuyerNeedAgentMatchLogV5",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotificationWidgetTextsForSaleProperty_aml"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ForSalePropertyV5",
                  "kind": "LinkedField",
                  "name": "source",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "ForSalePropertyAgentMatchLogV5",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotificationWidgetTextsRentalNeed_aml"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalNeedV5",
                  "kind": "LinkedField",
                  "name": "source",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "RentalNeedAgentMatchLogV5",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotificationWidgetTextsRentalProperty_aml"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RentalPropertyV5",
                  "kind": "LinkedField",
                  "name": "source",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "RentalPropertyAgentMatchLogV5",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "AgentMatchLogNotification",
      "abstractKey": null
    }
  ],
  "type": "NotificationV5",
  "abstractKey": "__isNotificationV5"
};
})());

var wrap_fragment = RescriptRelay_Internal.wrapUnion;

var wrap_fragment_AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2 = RescriptRelay_Internal.wrapUnion;

var wrap_fragment_AgentMatchLogNotification_agentMatchLogByType = RescriptRelay_Internal.wrapUnion;

var wrap_fragment_UpcomingShowingNotification_showing_property_agentV2 = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment = unwrap_fragment;
exports.wrap_fragment = wrap_fragment;
exports.unwrap_fragment_AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2 = unwrap_fragment_AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2;
exports.wrap_fragment_AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2 = wrap_fragment_AgentMatchLogNotification_agentMatchLogByType_ForSalePropertyAgentMatchLogV5_source_agentV2;
exports.unwrap_fragment_AgentMatchLogNotification_agentMatchLogByType = unwrap_fragment_AgentMatchLogNotification_agentMatchLogByType;
exports.wrap_fragment_AgentMatchLogNotification_agentMatchLogByType = wrap_fragment_AgentMatchLogNotification_agentMatchLogByType;
exports.unwrap_fragment_UpcomingShowingNotification_showing_property_agentV2 = unwrap_fragment_UpcomingShowingNotification_showing_property_agentV2;
exports.wrap_fragment_UpcomingShowingNotification_showing_property_agentV2 = wrap_fragment_UpcomingShowingNotification_showing_property_agentV2;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
