// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment_event(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "BroadcastPropertyCreatedAMLEvent",
              "ForSalePropertyEditedAMLEvent",
              "ForSalePropertyRebroadcastedAMLEvent",
              "QuietPropertyBroadcastedAMLEvent",
              "QuietPropertyCreatedAMLEvent"
            ]);
}

var fragmentConverter = {"__root":{"source_agentV2":{"f":""},"event_QuietPropertyCreatedAMLEvent_matches_edges_node_source_client":{"f":""},"event_QuietPropertyBroadcastedAMLEvent_newMatches_edges_node_source_client":{"f":""},"event_QuietPropertyBroadcastedAMLEvent_existingMatches_edges_node_source_client":{"f":""},"event_ForSalePropertyRebroadcastedAMLEvent_newMatches_edges_node_source_client":{"f":""},"event_ForSalePropertyRebroadcastedAMLEvent_existingMatches_edges_node_source_client":{"f":""},"event_ForSalePropertyEditedAMLEvent_newMatches_edges_node_source_client":{"f":""},"event_ForSalePropertyEditedAMLEvent_existingMatches_edges_node_source_client":{"f":""},"event_BroadcastPropertyCreatedAMLEvent_matches_edges_node_source_client":{"f":""},"event":{"u":"fragment_event"}}};

var fragmentConverterMap = {
  fragment_event: unwrap_fragment_event
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "BuyerNeedV5",
  "kind": "LinkedField",
  "name": "source",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Buyer",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "NotificationWidgetTextsClientLabel_client",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ClientContactsConnection",
                  "kind": "LinkedField",
                  "name": "contacts",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ClientContactsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ClientContactV5",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "displayName",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "ClientV5",
              "abstractKey": "__isClientV5"
            }
          ],
          "args": null,
          "argumentDefinitions": ([]/*: any*/)
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MatchForBuyerNeedV5",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v1/*: any*/)
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MatchForBuyerNeedV5",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "throwOnFieldError": true
  },
  "name": "NotificationWidgetTextsForSaleProperty_aml",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ForSalePropertyV5",
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "agentV2",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationWidgetTextsForSale_agent"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationWidgetTextsForSale_agentId"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BroadcastPropertyCreatedAMLEventMatchesConnection",
              "kind": "LinkedField",
              "name": "matches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BroadcastPropertyCreatedAMLEventMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "BroadcastPropertyCreatedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuietPropertyCreatedAMLEventMatchesConnection",
              "kind": "LinkedField",
              "name": "matches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuietPropertyCreatedAMLEventMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "QuietPropertyCreatedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ForSalePropertyEditedAMLEventExistingMatchesConnection",
              "kind": "LinkedField",
              "name": "existingMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ForSalePropertyEditedAMLEventExistingMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ForSalePropertyEditedAMLEventNewMatchesConnection",
              "kind": "LinkedField",
              "name": "newMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ForSalePropertyEditedAMLEventNewMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ForSalePropertyEditedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ForSalePropertyRebroadcastedAMLEventExistingMatchesConnection",
              "kind": "LinkedField",
              "name": "existingMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ForSalePropertyRebroadcastedAMLEventExistingMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ForSalePropertyRebroadcastedAMLEventNewMatchesConnection",
              "kind": "LinkedField",
              "name": "newMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ForSalePropertyRebroadcastedAMLEventNewMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ForSalePropertyRebroadcastedAMLEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuietPropertyBroadcastedAMLEventExistingMatchesConnection",
              "kind": "LinkedField",
              "name": "existingMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuietPropertyBroadcastedAMLEventExistingMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "QuietPropertyBroadcastedAMLEventNewMatchesConnection",
              "kind": "LinkedField",
              "name": "newMatches",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuietPropertyBroadcastedAMLEventNewMatchesConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "QuietPropertyBroadcastedAMLEvent",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ForSalePropertyAgentMatchLogV5",
  "abstractKey": null
};
})());

var wrap_fragment_event = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment_event = unwrap_fragment_event;
exports.wrap_fragment_event = wrap_fragment_event;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
