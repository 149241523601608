// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var UTCDate = require("../../../reason/common/types/UTCDate.bs.js");
var Belt_Map = require("rescript/lib/js/belt_Map.js");
var DateFns = require("date-fns");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");

function toString(notificationTime) {
  switch (notificationTime) {
    case "Today" :
        return "Today";
    case "Yesterday" :
        return "Yesterday";
    case "Last7Days" :
        return "Last 7 Days";
    case "OlderThanAWeek" :
        return "Older than a week";
    
  }
}

var cmp = Caml.int_compare;

var order = [
  "Today",
  "Yesterday",
  "Last7Days",
  "OlderThanAWeek"
];

function groupByDays(tuples) {
  var Cmp = Belt_Id.comparable(cmp);
  var empty = Belt_Map.make(Cmp);
  var secondElement = function (x) {
    return x[1];
  };
  var grouped = Core__Array.reduce(tuples, empty, (function (map, item) {
          return Belt_Map.update(map, secondElement(item), (function (extra) {
                        var v = item[0];
                        if (extra !== undefined) {
                          return extra.concat([v]);
                        } else {
                          return [v];
                        }
                      }));
        }));
  return order.map(function (ordinal) {
              return [
                      ordinal,
                      Belt_Map.get(grouped, ordinal)
                    ];
            });
}

function numberOfDaysToType(x) {
  if (x !== 0) {
    if (x !== 1) {
      if (x <= 7) {
        return "Last7Days";
      } else {
        return "OlderThanAWeek";
      }
    } else {
      return "Yesterday";
    }
  } else {
    return "Today";
  }
}

function groupNotifications(notifications, getDate) {
  var today = new Date();
  return groupByDays(notifications.map(function (n) {
                        return [
                                n,
                                DateFns.differenceInDays(today, UTCDate.toUnzonedDate(getDate(n)))
                              ];
                      }).map(function (x) {
                      return [
                              x[0],
                              numberOfDaysToType(x[1] | 0)
                            ];
                    })).map(function (x) {
                var vs = x[1];
                var k = x[0];
                if (vs !== undefined) {
                  return [
                          k,
                          vs
                        ];
                } else {
                  return [
                          k,
                          []
                        ];
                }
              }).filter(function (x) {
              return x[1].length !== 0;
            });
}

var NotificationGrouper = {
  toString: toString,
  cmp: cmp,
  order: order,
  groupByDays: groupByDays,
  numberOfDaysToType: numberOfDaysToType,
  groupNotifications: groupNotifications
};

exports.NotificationGrouper = NotificationGrouper;
/* UTCDate Not a pure module */
