// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Tooltip = require("../../../reason/common/Tooltip.bs.js");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var CurrentUser = require("../../../reason/common/CurrentUser/CurrentUser.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var ShowingsUtils = require("../../../v5/common/ShowingsUtils.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var NotificationWidgetTexts_showing_graphql = require("../../../__generated__/NotificationWidgetTexts_showing_graphql.bs.js");
var NotificationWidgetTextsAgent_agent_graphql = require("../../../__generated__/NotificationWidgetTextsAgent_agent_graphql.bs.js");
var NotificationWidgetTextsAgent_agentId_graphql = require("../../../__generated__/NotificationWidgetTextsAgent_agentId_graphql.bs.js");
var NotificationWidgetTextsBuyerNeed_aml_graphql = require("../../../__generated__/NotificationWidgetTextsBuyerNeed_aml_graphql.bs.js");
var NotificationWidgetTextsForSale_agent_graphql = require("../../../__generated__/NotificationWidgetTextsForSale_agent_graphql.bs.js");
var NotificationWidgetTextsRentalNeed_aml_graphql = require("../../../__generated__/NotificationWidgetTextsRentalNeed_aml_graphql.bs.js");
var NotificationWidgetTextsForSale_agentId_graphql = require("../../../__generated__/NotificationWidgetTextsForSale_agentId_graphql.bs.js");
var NotificationWidgetTextsClientLabel_client_graphql = require("../../../__generated__/NotificationWidgetTextsClientLabel_client_graphql.bs.js");
var NotificationWidgetTextsRentalProperty_aml_graphql = require("../../../__generated__/NotificationWidgetTextsRentalProperty_aml_graphql.bs.js");
var NotificationWidgetTextsForSaleProperty_aml_graphql = require("../../../__generated__/NotificationWidgetTextsForSaleProperty_aml_graphql.bs.js");
var NotificationWidgetTextsPropertyLocationLabel_property_graphql = require("../../../__generated__/NotificationWidgetTextsPropertyLocationLabel_property_graphql.bs.js");

var convertFragment = NotificationWidgetTextsPropertyLocationLabel_property_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsPropertyLocationLabel_property_graphql.node, convertFragment, fRef);
}

function readInline(fRef) {
  return RescriptRelay_Fragment.readInlineData(NotificationWidgetTextsPropertyLocationLabel_property_graphql.node, convertFragment, fRef);
}

var LocationFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  readInline: readInline
};

function getLabel($$location) {
  var $$location$1 = readInline($$location);
  var match = $$location$1.propertyLocation;
  if (match === undefined) {
    return "-";
  }
  switch (match.__typename) {
    case "PropertyLocationWithHiddenAddress" :
        var locationDisplay = match.locationDisplay;
        if (locationDisplay !== undefined) {
          return locationDisplay;
        } else {
          return "-";
        }
    case "PropertyLocationWithVisibleAddress" :
        var locationDisplay$1 = match.address;
        if (locationDisplay$1 !== undefined) {
          return locationDisplay$1;
        } else {
          return "-";
        }
    case "__unselected" :
        return "-";
    
  }
}

var PropertyLocationLabel = {
  LocationFragment: LocationFragment,
  getLabel: getLabel
};

var convertFragment$1 = NotificationWidgetTextsClientLabel_client_graphql.Internal.convertFragment;

function waitForFragmentData$1(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsClientLabel_client_graphql.node, convertFragment$1, fRef);
}

function readInline$1(fRef) {
  return RescriptRelay_Fragment.readInlineData(NotificationWidgetTextsClientLabel_client_graphql.node, convertFragment$1, fRef);
}

var ClientFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  waitForFragmentData: waitForFragmentData$1,
  readInline: readInline$1
};

function getLabel$1(client) {
  var client$1 = readInline$1(client);
  var contacts = Core__Array.keepSome(Core__Option.getOr(Core__Option.flatMap(client$1.contacts, (function (c) {
                    return c.edges;
                  })), [])).map(function (c) {
        return c.node.displayName;
      });
  if (contacts.length !== 0) {
    return contacts.join(", ");
  }
  
}

var ClientLabel = {
  ClientFragment: ClientFragment,
  getLabel: getLabel$1
};

var convertFragment$2 = NotificationWidgetTextsAgent_agent_graphql.Internal.convertFragment;

function waitForFragmentData$2(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsAgent_agent_graphql.node, convertFragment$2, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsAgent_agent_graphql.node, convertFragment$2, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsAgent_agent_graphql.node, convertFragment$2);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetTextsAgent_agent_graphql.node, convertFragment$2, fRef);
}

var Agent = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  waitForFragmentData: waitForFragmentData$2,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function useAgentTextOpt(fragmentRefs) {
  var currentUser = CurrentUser.useCurrentUser();
  var data = useOpt(fragmentRefs);
  if (data === undefined) {
    return "A TAN agent";
  }
  var displayName = data.displayName;
  var match = currentUser.user;
  if (match !== undefined && match.id === data.id) {
    return "You";
  } else {
    return displayName;
  }
}

function useAgentText(fragmentRefs) {
  return useAgentTextOpt(Caml_option.some(fragmentRefs));
}

var convertFragment$3 = NotificationWidgetTextsForSale_agent_graphql.Internal.convertFragment;

function waitForFragmentData$3(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsForSale_agent_graphql.node, convertFragment$3, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsForSale_agent_graphql.node, convertFragment$3, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsForSale_agent_graphql.node, convertFragment$3);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetTextsForSale_agent_graphql.node, convertFragment$3, fRef);
}

var ForSaleAgent = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$3,
  waitForFragmentData: waitForFragmentData$3,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function useForSaleAgentText(fragmentRefs) {
  var data = use$1(fragmentRefs);
  var fragmentRef;
  switch (data.__typename) {
    case "VisibleForSalePropertyAgent" :
        var match = data.agent;
        fragmentRef = match !== undefined ? Caml_option.some(match.fragmentRefs) : undefined;
        break;
    case "AnonymousForSalePropertyAgent" :
    case "__unselected" :
        fragmentRef = undefined;
        break;
    
  }
  var text = useAgentTextOpt(fragmentRef);
  switch (data.__typename) {
    case "AnonymousForSalePropertyAgent" :
        return "A TAN agent";
    case "VisibleForSalePropertyAgent" :
    case "__unselected" :
        return text;
    
  }
}

var AgentText = {
  Agent: Agent,
  useAgentTextOpt: useAgentTextOpt,
  useAgentText: useAgentText,
  ForSaleAgent: ForSaleAgent,
  useForSaleAgentText: useForSaleAgentText
};

var convertFragment$4 = NotificationWidgetTextsAgent_agentId_graphql.Internal.convertFragment;

function waitForFragmentData$4(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsAgent_agentId_graphql.node, convertFragment$4, fRef);
}

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsAgent_agentId_graphql.node, convertFragment$4, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsAgent_agentId_graphql.node, convertFragment$4);
}

function readResolverFragment$2(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetTextsAgent_agentId_graphql.node, convertFragment$4, fRef);
}

var Agent$1 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$4,
  waitForFragmentData: waitForFragmentData$4,
  use: use$2,
  useOpt: useOpt$2,
  readResolverFragment: readResolverFragment$2
};

function useAgentId(fragmentRefs) {
  return use$2(fragmentRefs).id;
}

var convertFragment$5 = NotificationWidgetTextsForSale_agentId_graphql.Internal.convertFragment;

function waitForFragmentData$5(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsForSale_agentId_graphql.node, convertFragment$5, fRef);
}

function use$3(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsForSale_agentId_graphql.node, convertFragment$5, fRef);
}

function useOpt$3(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsForSale_agentId_graphql.node, convertFragment$5);
}

function readResolverFragment$3(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetTextsForSale_agentId_graphql.node, convertFragment$5, fRef);
}

var ForSaleAgent$1 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$5,
  waitForFragmentData: waitForFragmentData$5,
  use: use$3,
  useOpt: useOpt$3,
  readResolverFragment: readResolverFragment$3
};

function useForSaleAgentId(fragmentRefs) {
  var data = use$3(fragmentRefs);
  if (data.__typename !== "VisibleForSalePropertyAgent") {
    return ;
  }
  var match = data.agent;
  if (match !== undefined) {
    return match.id;
  }
  
}

var AgentId = {
  Agent: Agent$1,
  useAgentId: useAgentId,
  ForSaleAgent: ForSaleAgent$1,
  useForSaleAgentId: useForSaleAgentId
};

var convertFragment$6 = NotificationWidgetTexts_showing_graphql.Internal.convertFragment;

function waitForFragmentData$6(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTexts_showing_graphql.node, convertFragment$6, fRef);
}

function use$4(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTexts_showing_graphql.node, convertFragment$6, fRef);
}

function useOpt$4(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTexts_showing_graphql.node, convertFragment$6);
}

function readResolverFragment$4(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetTexts_showing_graphql.node, convertFragment$6, fRef);
}

var ShowingFragment_visibilityReason_decode = NotificationWidgetTexts_showing_graphql.Utils.visibilityReason_decode;

var ShowingFragment_visibilityReason_fromString = NotificationWidgetTexts_showing_graphql.Utils.visibilityReason_fromString;

var ShowingFragment = {
  visibilityReason_decode: ShowingFragment_visibilityReason_decode,
  visibilityReason_fromString: ShowingFragment_visibilityReason_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$6,
  waitForFragmentData: waitForFragmentData$6,
  use: use$4,
  useOpt: useOpt$4,
  readResolverFragment: readResolverFragment$4
};

function NotificationWidgetTexts$ShowingNotification(props) {
  var showing = use$4(props.showing);
  var agentId = useForSaleAgentId(showing.property.agentV2.fragmentRefs);
  var agentText = useForSaleAgentText(showing.property.agentV2.fragmentRefs);
  var verb = agentText === "You" ? "are" : "is";
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(agentId, undefined, undefined);
  var $$location = getLabel(showing.property.fragmentRefs);
  var duration = ShowingsUtils.formatShowingTimes(showing.startDateV2, showing.endDateV2);
  var match = showing.property.propertyLocation;
  var match$1;
  if (match !== undefined) {
    switch (match.__typename) {
      case "PropertyLocationWithHiddenAddress" :
          match$1 = [
            undefined,
            match.locationDisplay,
            undefined
          ];
          break;
      case "PropertyLocationWithVisibleAddress" :
          match$1 = [
            match.address,
            match.locationDisplay,
            match.visibilityRule
          ];
          break;
      case "__unselected" :
          match$1 = [
            undefined,
            "-",
            undefined
          ];
          break;
      
    }
  } else {
    match$1 = [
      undefined,
      "-",
      undefined
    ];
  }
  var visibilityRule = match$1[2];
  var locationDisplay = match$1[1];
  var address = match$1[0];
  var tmp;
  if (shouldShowPropertyDetails) {
    var tmp$1;
    if (address !== undefined) {
      var exit = 0;
      if (visibilityRule !== undefined) {
        var exit$1 = 0;
        if (visibilityRule === "VISIBLE_TO_ALL" || visibilityRule === "VISIBLE_TO_ACCOUNT_MEMBER" || visibilityRule === "VISIBLE_TO_TAN_STAFF") {
          switch (visibilityRule) {
            case "VISIBLE_TO_ALL" :
                exit = 1;
                break;
            case "VISIBLE_TO_ACCOUNT_MEMBER" :
            case "VISIBLE_TO_TAN_STAFF" :
                exit$1 = 2;
                break;
            
          }
        } else {
          exit = 1;
        }
        if (exit$1 === 2) {
          var tmp$2;
          if (visibilityRule === "VISIBLE_TO_ALL" || visibilityRule === "VISIBLE_TO_ACCOUNT_MEMBER" || visibilityRule === "VISIBLE_TO_TAN_STAFF") {
            switch (visibilityRule) {
              case "VISIBLE_TO_ACCOUNT_MEMBER" :
                  tmp$2 = " (only visible to you)";
                  break;
              case "VISIBLE_TO_ALL" :
                  tmp$2 = "";
                  break;
              case "VISIBLE_TO_TAN_STAFF" :
                  tmp$2 = " (visible to TAN staff)";
                  break;
              
            }
          } else {
            tmp$2 = "";
          }
          tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx("span", {
                        children: locationDisplay
                      }),
                  JsxRuntime.jsx("span", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Icon__Jsx3.make, {
                                      icon: "Lock",
                                      className: "text-gray-600 mr-1",
                                      size: 16
                                    }),
                                JsxRuntime.jsx(Tooltip.make, {
                                      label: address + tmp$2,
                                      children: JsxRuntime.jsx("div", {
                                            children: "Hidden Address",
                                            className: "border-dotted border-b border-gray-400"
                                          })
                                    })
                              ],
                              className: "inline-flex flex-row items-center lg:before:content-['('] lg:after:content-[')'] before:content-none after:content-none"
                            }),
                        className: ""
                      })
                ]
              });
        }
        
      } else {
        exit = 1;
      }
      if (exit === 1) {
        tmp$1 = JsxRuntime.jsx("span", {
              children: address + " in " + locationDisplay
            });
      }
      
    } else {
      tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx("span", {
                    children: locationDisplay
                  }),
              JsxRuntime.jsx("span", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(Icon__Jsx3.make, {
                                  icon: "Lock",
                                  className: "text-gray-600 mr-1",
                                  size: 16
                                }),
                            JsxRuntime.jsx("div", {
                                  children: "Contact agent for address"
                                })
                          ],
                          className: "ml-1 inline-flex flex-row items-center lg:before:content-['('] lg:after:content-[')'] before:content-none after:content-none"
                        }),
                    className: ""
                  })
            ]
          });
    }
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            agentText + " " + verb + " showing ",
            JsxRuntime.jsx("span", {
                  children: tmp$1,
                  className: ""
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsx(JsxRuntime.Fragment, {
          children: Caml_option.some("A TAN agent is showing " + $$location)
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                tmp,
                JsxRuntime.jsx("br", {}),
                duration
              ]
            });
}

var ShowingNotification = {
  ShowingFragment: ShowingFragment,
  make: NotificationWidgetTexts$ShowingNotification
};

var convertFragment$7 = NotificationWidgetTextsForSaleProperty_aml_graphql.Internal.convertFragment;

function waitForFragmentData$7(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsForSaleProperty_aml_graphql.node, convertFragment$7, fRef);
}

function use$5(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsForSaleProperty_aml_graphql.node, convertFragment$7, fRef);
}

function useOpt$5(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsForSaleProperty_aml_graphql.node, convertFragment$7);
}

function readResolverFragment$5(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetTextsForSaleProperty_aml_graphql.node, convertFragment$7, fRef);
}

var AmlFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$7,
  waitForFragmentData: waitForFragmentData$7,
  use: use$5,
  useOpt: useOpt$5,
  readResolverFragment: readResolverFragment$5
};

function NotificationWidgetTexts$PropertyMatchText(props) {
  var aml = use$5(props.aml);
  var agentId = useForSaleAgentId(aml.source.agentV2.fragmentRefs);
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(agentId, undefined, undefined);
  var agentText = useForSaleAgentText(aml.source.agentV2.fragmentRefs);
  var match = aml.event;
  var verb;
  switch (match.__typename) {
    case "BroadcastPropertyCreatedAMLEvent" :
    case "QuietPropertyCreatedAMLEvent" :
        verb = "added";
        break;
    case "__unselected" :
        verb = "-";
        break;
    default:
      verb = "updated";
  }
  var match$1 = aml.event;
  var matches;
  switch (match$1.__typename) {
    case "BroadcastPropertyCreatedAMLEvent" :
        var match$2 = match$1.matches;
        matches = match$2 !== undefined ? ({
              TAG: "Matches",
              _0: Core__Array.keepSome(Core__Array.keepSome(match$2.edges).map(function (e) {
                        return Core__Option.map(e.node.source.client, (function (v) {
                                      return v.fragmentRefs;
                                    }));
                      }))
            }) : ({
              TAG: "Matches",
              _0: []
            });
        break;
    case "ForSalePropertyEditedAMLEvent" :
        matches = {
          TAG: "ExistingAndNew",
          new: Core__Array.keepSome(Core__Array.keepSome(match$1.newMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  })),
          existing: Core__Array.keepSome(Core__Array.keepSome(match$1.existingMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  }))
        };
        break;
    case "ForSalePropertyRebroadcastedAMLEvent" :
        matches = {
          TAG: "ExistingAndNew",
          new: Core__Array.keepSome(Core__Array.keepSome(match$1.newMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  })),
          existing: Core__Array.keepSome(Core__Array.keepSome(match$1.existingMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  }))
        };
        break;
    case "QuietPropertyBroadcastedAMLEvent" :
        matches = {
          TAG: "ExistingAndNew",
          new: Core__Array.keepSome(Core__Array.keepSome(match$1.newMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  })),
          existing: Core__Array.keepSome(Core__Array.keepSome(match$1.existingMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  }))
        };
        break;
    case "QuietPropertyCreatedAMLEvent" :
        var match$3 = match$1.matches;
        matches = match$3 !== undefined ? ({
              TAG: "Matches",
              _0: Core__Array.keepSome(Core__Array.keepSome(match$3.edges).map(function (e) {
                        return Core__Option.map(e.node.source.client, (function (v) {
                                      return v.fragmentRefs;
                                    }));
                      }))
            }) : ({
              TAG: "Matches",
              _0: []
            });
        break;
    case "__unselected" :
        matches = {
          TAG: "Matches",
          _0: []
        };
        break;
    
  }
  var matchesText;
  var exit = 0;
  var client;
  var matches$1;
  if (matches.TAG === "Matches") {
    var matches$2 = matches._0;
    if (matches$2.length !== 1) {
      matches$1 = matches$2;
      exit = 2;
    } else {
      var client$1 = matches$2[0];
      client = client$1;
      exit = 1;
    }
  } else {
    var matches$3 = matches.new;
    var exit$1 = 0;
    var len = matches$3.length;
    if (len !== 1) {
      if (len !== 0) {
        exit$1 = 3;
      } else {
        var matches$4 = matches.existing;
        if (matches$4.length !== 1) {
          matches$1 = matches$4;
          exit = 2;
        } else {
          var client$2 = matches$4[0];
          client = client$2;
          exit = 1;
        }
      }
    } else {
      var client$3 = matches$3[0];
      if (matches.existing.length !== 0) {
        exit$1 = 3;
      } else {
        client = client$3;
        exit = 1;
      }
    }
    if (exit$1 === 3) {
      if (matches.existing.length !== 0) {
        var length = matches$3.length;
        matchesText = length.toString() + " more of your buyers";
      } else {
        matches$1 = matches$3;
        exit = 2;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        var clientName = Core__Option.getOr(getLabel$1(client), "client name");
        matchesText = "your buyer " + clientName;
        break;
    case 2 :
        var length$1 = matches$1.length;
        matchesText = length$1.toString() + " of your buyers";
        break;
    
  }
  if (shouldShowPropertyDetails) {
    return agentText + " " + verb + " a property that matches " + matchesText;
  } else {
    return "A TAN agent " + verb + " a property that matches " + matchesText;
  }
}

var PropertyMatchText = {
  AmlFragment: AmlFragment,
  make: NotificationWidgetTexts$PropertyMatchText
};

var convertFragment$8 = NotificationWidgetTextsRentalProperty_aml_graphql.Internal.convertFragment;

function waitForFragmentData$8(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsRentalProperty_aml_graphql.node, convertFragment$8, fRef);
}

function use$6(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsRentalProperty_aml_graphql.node, convertFragment$8, fRef);
}

function useOpt$6(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsRentalProperty_aml_graphql.node, convertFragment$8);
}

function readResolverFragment$6(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetTextsRentalProperty_aml_graphql.node, convertFragment$8, fRef);
}

var AmlFragment$1 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$8,
  waitForFragmentData: waitForFragmentData$8,
  use: use$6,
  useOpt: useOpt$6,
  readResolverFragment: readResolverFragment$6
};

function NotificationWidgetTexts$RentalPropertyMatchText(props) {
  var aml = use$6(props.aml);
  var agentId = use$2(aml.source.agent.fragmentRefs).id;
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(agentId, undefined, undefined);
  var agentText = useAgentTextOpt(Caml_option.some(aml.source.agent.fragmentRefs));
  var match = aml.event;
  var verb;
  switch (match.__typename) {
    case "RentalPropertyCreatedAMLEvent" :
        verb = "added";
        break;
    case "RentalPropertyEditedAMLEvent" :
    case "RentalPropertyRebroadcastedAMLEvent" :
        verb = "updated";
        break;
    case "__unselected" :
        verb = "-";
        break;
    
  }
  var match$1 = aml.event;
  var mtches;
  switch (match$1.__typename) {
    case "RentalPropertyCreatedAMLEvent" :
        mtches = {
          TAG: "Matches",
          _0: Core__Array.keepSome(Core__Array.keepSome(match$1.matches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  }))
        };
        break;
    case "RentalPropertyEditedAMLEvent" :
        mtches = {
          TAG: "ExistingAndNew",
          new: Core__Array.keepSome(Core__Array.keepSome(match$1.newMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  })),
          existing: Core__Array.keepSome(Core__Array.keepSome(match$1.existingMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  }))
        };
        break;
    case "RentalPropertyRebroadcastedAMLEvent" :
        mtches = {
          TAG: "ExistingAndNew",
          new: Core__Array.keepSome(Core__Array.keepSome(match$1.newMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  })),
          existing: Core__Array.keepSome(Core__Array.keepSome(match$1.existingMatches.edges).map(function (e) {
                    return Core__Option.map(e.node.source.client, (function (v) {
                                  return v.fragmentRefs;
                                }));
                  }))
        };
        break;
    case "__unselected" :
        mtches = {
          TAG: "Matches",
          _0: []
        };
        break;
    
  }
  var matchesText;
  var exit = 0;
  var client;
  var matches;
  if (mtches.TAG === "Matches") {
    var matches$1 = mtches._0;
    if (matches$1.length !== 1) {
      matches = matches$1;
      exit = 2;
    } else {
      var client$1 = matches$1[0];
      client = client$1;
      exit = 1;
    }
  } else {
    var matches$2 = mtches.new;
    var exit$1 = 0;
    var len = matches$2.length;
    if (len !== 1) {
      if (len !== 0) {
        exit$1 = 3;
      } else {
        var match$2 = mtches.existing;
        if (match$2.length !== 1) {
          exit$1 = 3;
        } else {
          var client$2 = match$2[0];
          client = client$2;
          exit = 1;
        }
      }
    } else {
      var client$3 = matches$2[0];
      if (mtches.existing.length !== 0) {
        exit$1 = 3;
      } else {
        client = client$3;
        exit = 1;
      }
    }
    if (exit$1 === 3) {
      if (mtches.existing.length !== 0) {
        if (matches$2.length !== 0) {
          var length = matches$2.length;
          matchesText = length.toString() + " more of your clients";
        } else {
          matches = mtches.existing;
          exit = 2;
        }
      } else {
        matches = matches$2;
        exit = 2;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        var clientName = Core__Option.getOr(getLabel$1(client), "client name");
        matchesText = "your buyer " + clientName;
        break;
    case 2 :
        var length$1 = matches.length;
        matchesText = length$1.toString() + " of your clients";
        break;
    
  }
  if (shouldShowPropertyDetails) {
    return agentText + " " + verb + " a rental property that matches " + matchesText;
  } else {
    return "A TAN agent " + verb + " a rental property that matches " + matchesText;
  }
}

var RentalPropertyMatchText = {
  AmlFragment: AmlFragment$1,
  make: NotificationWidgetTexts$RentalPropertyMatchText
};

var convertFragment$9 = NotificationWidgetTextsBuyerNeed_aml_graphql.Internal.convertFragment;

function waitForFragmentData$9(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsBuyerNeed_aml_graphql.node, convertFragment$9, fRef);
}

function use$7(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsBuyerNeed_aml_graphql.node, convertFragment$9, fRef);
}

function useOpt$7(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsBuyerNeed_aml_graphql.node, convertFragment$9);
}

function readResolverFragment$7(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetTextsBuyerNeed_aml_graphql.node, convertFragment$9, fRef);
}

var AmlFragment$2 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$9,
  waitForFragmentData: waitForFragmentData$9,
  use: use$7,
  useOpt: useOpt$7,
  readResolverFragment: readResolverFragment$7
};

function NotificationWidgetTexts$BuyerNeedMatchText(props) {
  var aml = use$7(props.aml);
  var agentText = useAgentTextOpt(Caml_option.some(aml.source.agent.fragmentRefs));
  var match = aml.event;
  var verb;
  switch (match.__typename) {
    case "BuyerNeedEditedAMLEvent" :
    case "QuietBuyerNeedBroadcastedAMLEvent" :
        verb = "updated";
        break;
    case "BroadcastBuyerNeedCreatedAMLEvent" :
    case "QuietBuyerNeedCreatedAMLEvent" :
        verb = agentText === "You" ? "have" : "has";
        break;
    case "__unselected" :
        verb = "-";
        break;
    
  }
  var match$1 = aml.event;
  var matches;
  switch (match$1.__typename) {
    case "BroadcastBuyerNeedCreatedAMLEvent" :
        matches = {
          TAG: "MatchesWithLocation",
          _0: Core__Array.keepSome(match$1.matches.edges).map(function (e) {
                return {
                        client: Core__Option.map(e.node.source.client, (function (v) {
                                return v.fragmentRefs;
                              })),
                        location: e.node.source.fragmentRefs
                      };
              })
        };
        break;
    case "BuyerNeedEditedAMLEvent" :
        matches = {
          TAG: "ExistingAndNewWithLocation",
          new: Core__Array.keepSome(match$1.newMatches.edges).map(function (e) {
                return {
                        client: Core__Option.map(e.node.source.client, (function (v) {
                                return v.fragmentRefs;
                              })),
                        location: e.node.source.fragmentRefs
                      };
              }),
          existing: Core__Array.keepSome(match$1.existingMatches.edges).map(function (e) {
                return {
                        client: Core__Option.map(e.node.source.client, (function (v) {
                                return v.fragmentRefs;
                              })),
                        location: e.node.source.fragmentRefs
                      };
              })
        };
        break;
    case "QuietBuyerNeedBroadcastedAMLEvent" :
        matches = {
          TAG: "ExistingAndNewWithLocation",
          new: Core__Array.keepSome(match$1.newMatches.edges).map(function (e) {
                return {
                        client: Core__Option.map(e.node.source.client, (function (v) {
                                return v.fragmentRefs;
                              })),
                        location: e.node.source.fragmentRefs
                      };
              }),
          existing: Core__Array.keepSome(match$1.existingMatches.edges).map(function (e) {
                return {
                        client: Core__Option.map(e.node.source.client, (function (v) {
                                return v.fragmentRefs;
                              })),
                        location: e.node.source.fragmentRefs
                      };
              })
        };
        break;
    case "QuietBuyerNeedCreatedAMLEvent" :
        matches = {
          TAG: "MatchesWithLocation",
          _0: Core__Array.keepSome(match$1.matches.edges).map(function (e) {
                return {
                        client: Core__Option.map(e.node.source.client, (function (v) {
                                return v.fragmentRefs;
                              })),
                        location: e.node.source.fragmentRefs
                      };
              })
        };
        break;
    case "__unselected" :
        matches = {
          TAG: "MatchesWithLocation",
          _0: []
        };
        break;
    
  }
  var matchesText;
  var exit = 0;
  var client;
  var $$location;
  var matches$1;
  if (matches.TAG === "MatchesWithLocation") {
    var matches$2 = matches._0;
    if (matches$2.length !== 1) {
      matches$1 = matches$2;
      exit = 2;
    } else {
      var match$2 = matches$2[0];
      client = match$2.client;
      $$location = match$2.location;
      exit = 1;
    }
  } else {
    var matches$3 = matches.new;
    var exit$1 = 0;
    var len = matches$3.length;
    if (len !== 1) {
      if (len !== 0) {
        exit$1 = 3;
      } else {
        var matches$4 = matches.existing;
        if (matches$4.length !== 1) {
          matches$1 = matches$4;
          exit = 2;
        } else {
          var match$3 = matches$4[0];
          client = match$3.client;
          $$location = match$3.location;
          exit = 1;
        }
      }
    } else {
      var match$4 = matches$3[0];
      if (matches.existing.length !== 0) {
        exit$1 = 3;
      } else {
        client = match$4.client;
        $$location = match$4.location;
        exit = 1;
      }
    }
    if (exit$1 === 3) {
      if (matches.existing.length !== 0) {
        matchesText = matches$3.length.toString() + " more of your sellers";
      } else {
        matches$1 = matches$3;
        exit = 2;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        matchesText = Core__Option.getOr(Core__Option.map(Core__Option.flatMap(client, (function (c) {
                        return getLabel$1(c);
                      })), (function (name) {
                    return name + "'s property";
                  })), getLabel($$location));
        break;
    case 2 :
        matchesText = matches$1.length.toString() + " of your sellers";
        break;
    
  }
  return agentText + " " + verb + " a buyer that matches " + matchesText;
}

var BuyerNeedMatchText = {
  AmlFragment: AmlFragment$2,
  make: NotificationWidgetTexts$BuyerNeedMatchText
};

var convertFragment$10 = NotificationWidgetTextsRentalNeed_aml_graphql.Internal.convertFragment;

function waitForFragmentData$10(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetTextsRentalNeed_aml_graphql.node, convertFragment$10, fRef);
}

function use$8(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsRentalNeed_aml_graphql.node, convertFragment$10, fRef);
}

function useOpt$8(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsRentalNeed_aml_graphql.node, convertFragment$10);
}

function readResolverFragment$8(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetTextsRentalNeed_aml_graphql.node, convertFragment$10, fRef);
}

var AmlFragment$3 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$10,
  waitForFragmentData: waitForFragmentData$10,
  use: use$8,
  useOpt: useOpt$8,
  readResolverFragment: readResolverFragment$8
};

function NotificationWidgetTexts$RentalNeedMatchText(props) {
  var aml = use$8(props.aml);
  var agentText = useAgentTextOpt(Caml_option.some(aml.source.agent.fragmentRefs));
  var match = aml.event;
  var verb;
  switch (match.__typename) {
    case "RentalNeedCreatedAMLEvent" :
        verb = agentText === "You" ? "have" : "has";
        break;
    case "RentalNeedEditedAMLEvent" :
        verb = "updated";
        break;
    case "__unselected" :
        verb = "-";
        break;
    
  }
  var match$1 = aml.event;
  var matches;
  switch (match$1.__typename) {
    case "RentalNeedCreatedAMLEvent" :
        matches = {
          TAG: "MatchesWithLocation",
          _0: Core__Array.keepSome(match$1.matches.edges).map(function (e) {
                return {
                        client: Core__Option.map(e.node.source.client, (function (v) {
                                return v.fragmentRefs;
                              })),
                        location: e.node.source.fragmentRefs
                      };
              })
        };
        break;
    case "RentalNeedEditedAMLEvent" :
        matches = {
          TAG: "ExistingAndNewWithLocation",
          new: Core__Array.keepSome(match$1.newMatches.edges).map(function (e) {
                return {
                        client: Core__Option.map(e.node.source.client, (function (v) {
                                return v.fragmentRefs;
                              })),
                        location: e.node.source.fragmentRefs
                      };
              }),
          existing: Core__Array.keepSome(match$1.existingMatches.edges).map(function (e) {
                return {
                        client: Core__Option.map(e.node.source.client, (function (v) {
                                return v.fragmentRefs;
                              })),
                        location: e.node.source.fragmentRefs
                      };
              })
        };
        break;
    case "__unselected" :
        matches = {
          TAG: "MatchesWithLocation",
          _0: []
        };
        break;
    
  }
  var matchesText;
  var exit = 0;
  var client;
  var $$location;
  var matches$1;
  if (matches.TAG === "MatchesWithLocation") {
    var matches$2 = matches._0;
    if (matches$2.length !== 1) {
      matches$1 = matches$2;
      exit = 2;
    } else {
      var match$2 = matches$2[0];
      client = match$2.client;
      $$location = match$2.location;
      exit = 1;
    }
  } else {
    var matches$3 = matches.new;
    var exit$1 = 0;
    var len = matches$3.length;
    if (len !== 1) {
      if (len !== 0) {
        exit$1 = 3;
      } else {
        var match$3 = matches.existing;
        if (match$3.length !== 1) {
          exit$1 = 3;
        } else {
          var match$4 = match$3[0];
          client = match$4.client;
          $$location = match$4.location;
          exit = 1;
        }
      }
    } else {
      var match$5 = matches$3[0];
      if (matches.existing.length !== 0) {
        exit$1 = 3;
      } else {
        client = match$5.client;
        $$location = match$5.location;
        exit = 1;
      }
    }
    if (exit$1 === 3) {
      if (matches.existing.length !== 0) {
        if (matches$3.length !== 0) {
          matchesText = matches$3.length.toString() + " more of your clients";
        } else {
          matches$1 = matches.existing;
          exit = 2;
        }
      } else {
        matches$1 = matches$3;
        exit = 2;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        matchesText = Core__Option.getOr(Core__Option.map(Core__Option.flatMap(client, (function (c) {
                        return getLabel$1(c);
                      })), (function (name) {
                    return name + "'s property";
                  })), getLabel($$location));
        break;
    case 2 :
        matchesText = matches$1.length.toString() + " of your clients";
        break;
    
  }
  return agentText + " " + verb + " a client that matches " + matchesText;
}

var RentalNeedMatchText = {
  AmlFragment: AmlFragment$3,
  make: NotificationWidgetTexts$RentalNeedMatchText
};

exports.PropertyLocationLabel = PropertyLocationLabel;
exports.ClientLabel = ClientLabel;
exports.AgentText = AgentText;
exports.AgentId = AgentId;
exports.ShowingNotification = ShowingNotification;
exports.PropertyMatchText = PropertyMatchText;
exports.RentalPropertyMatchText = RentalPropertyMatchText;
exports.BuyerNeedMatchText = BuyerNeedMatchText;
exports.RentalNeedMatchText = RentalNeedMatchText;
/* Tooltip Not a pure module */
