// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Link = require("next/link").default;
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Next__Atoms = require("../../../reason/next/Next__Atoms.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var RescriptCore = require("@rescript/core/src/RescriptCore.bs.js");
var TailwindMerge = require("tailwind-merge");
var AgentProfilePhoto = require("../../../v5/common/Agent/AgentProfilePhoto.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var NotificationTypeIcon = require("./NotificationTypeIcon.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var NotificationWidgetTexts = require("./NotificationWidgetTexts.bs.js");
var NotificationWidgetItem_notification_graphql = require("../../../__generated__/NotificationWidgetItem_notification_graphql.bs.js");
var NotificationWidgetItemSource_notification_graphql = require("../../../__generated__/NotificationWidgetItemSource_notification_graphql.bs.js");

var convertFragment = NotificationWidgetItem_notification_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetItem_notification_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetItem_notification_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetItem_notification_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetItem_notification_graphql.node, convertFragment, fRef);
}

var NotificationFragment_notificationStatus_decode = NotificationWidgetItem_notification_graphql.Utils.notificationStatus_decode;

var NotificationFragment_notificationStatus_fromString = NotificationWidgetItem_notification_graphql.Utils.notificationStatus_fromString;

var NotificationFragment = {
  notificationStatus_decode: NotificationFragment_notificationStatus_decode,
  notificationStatus_fromString: NotificationFragment_notificationStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = NotificationWidgetItemSource_notification_graphql.Internal.convertFragment;

function waitForFragmentData$1(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, NotificationWidgetItemSource_notification_graphql.node, convertFragment$1, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetItemSource_notification_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetItemSource_notification_graphql.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(NotificationWidgetItemSource_notification_graphql.node, convertFragment$1, fRef);
}

var NotificationSourceFragment_draftOrigin_decode = NotificationWidgetItemSource_notification_graphql.Utils.draftOrigin_decode;

var NotificationSourceFragment_draftOrigin_fromString = NotificationWidgetItemSource_notification_graphql.Utils.draftOrigin_fromString;

var NotificationSourceFragment = {
  draftOrigin_decode: NotificationSourceFragment_draftOrigin_decode,
  draftOrigin_fromString: NotificationSourceFragment_draftOrigin_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  waitForFragmentData: waitForFragmentData$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

var avatarStyle = "w-[50px] h-[50px]";

function NotificationWidgetItem(props) {
  var markAsRead = props.markAsRead;
  var notification = use(props.notification);
  var source = use$1(notification.fragmentRefs);
  var onClick = function (_e) {
    var match = notification.status;
    if (match !== undefined && (match === "READ" || match === "UNREAD") && match !== "READ") {
      return markAsRead(notification.id);
    }
    
  };
  var match;
  switch (source.__typename) {
    case "AgentMatchLogNotification" :
        match = [
          "View match",
          "/match_log/" + source.agentMatchLog.databaseId
        ];
        break;
    case "UnfinishedDraftNotification" :
        var match$1 = source.draft;
        var match$2 = match$1.origin;
        if (match$2 !== undefined) {
          var exit = 0;
          if (match$2 === "NEW" || match$2 === "BROADCAST_QUIET_SELLER" || match$2 === "REBROADCAST") {
            switch (match$2) {
              case "NEW" :
                  match = [
                    "Resume draft",
                    "/properties/new?draftId=" + match$1.databaseId
                  ];
                  break;
              case "BROADCAST_QUIET_SELLER" :
              case "REBROADCAST" :
                  exit = 1;
                  break;
              
            }
          } else {
            match = RescriptCore.panic("Unexpected source.");
          }
          if (exit === 1) {
            var match$3 = match$1.publishedProperty;
            match = match$3 !== undefined ? [
                "Resume draft",
                "/properties/" + match$3.id + "/broadcast?resumingDraft=1"
              ] : RescriptCore.panic("Unexpected source.");
          }
          
        } else {
          match = RescriptCore.panic("Unexpected source.");
        }
        break;
    case "UpcomingShowingNotification" :
        match = [
          "View details",
          "/properties/" + source.showing.property.id
        ];
        break;
    case "__unselected" :
        match = RescriptCore.panic("Unexpected source.");
        break;
    
  }
  var link = match[1];
  var tmp;
  var exit$1 = 0;
  switch (source.__typename) {
    case "AgentMatchLogNotification" :
        var match$4 = source.agentMatchLogByType;
        if (match$4 !== undefined) {
          switch (match$4.__typename) {
            case "ForSalePropertyAgentMatchLogV5" :
                var match$5 = match$4.source;
                if (match$5 !== undefined) {
                  var match$6 = match$5.agentV2;
                  if (match$6 !== undefined && match$6.__typename === "VisibleForSalePropertyAgent") {
                    var match$7 = match$6.agent;
                    if (match$7 !== undefined) {
                      tmp = JsxRuntime.jsx(AgentProfilePhoto.make, {
                            profilePhoto: match$7.fragmentRefs,
                            className: avatarStyle
                          });
                    } else {
                      exit$1 = 1;
                    }
                  } else {
                    exit$1 = 1;
                  }
                } else {
                  exit$1 = 1;
                }
                break;
            case "__unselected" :
                exit$1 = 1;
                break;
            default:
              var match$8 = match$4.source;
              if (match$8 !== undefined) {
                var match$9 = match$8.agent;
                if (match$9 !== undefined) {
                  tmp = JsxRuntime.jsx(AgentProfilePhoto.make, {
                        profilePhoto: match$9.fragmentRefs,
                        className: avatarStyle
                      });
                } else {
                  exit$1 = 1;
                }
              } else {
                exit$1 = 1;
              }
          }
        } else {
          exit$1 = 1;
        }
        break;
    case "UnfinishedDraftNotification" :
        tmp = JsxRuntime.jsx(NotificationTypeIcon.make, {
              children: JsxRuntime.jsx(Icon__Jsx3.make, {
                    icon: "Draft",
                    size: 30
                  })
            });
        break;
    case "UpcomingShowingNotification" :
        var match$10 = source.showing.property.agentV2;
        if (match$10 !== undefined && match$10.__typename === "VisibleForSalePropertyAgent") {
          var match$11 = match$10.agent;
          if (match$11 !== undefined) {
            tmp = JsxRuntime.jsx(AgentProfilePhoto.make, {
                  profilePhoto: match$11.fragmentRefs,
                  className: avatarStyle
                });
          } else {
            exit$1 = 1;
          }
        } else {
          exit$1 = 1;
        }
        break;
    case "__unselected" :
        exit$1 = 1;
        break;
    
  }
  if (exit$1 === 1) {
    tmp = JsxRuntime.jsx(AgentProfilePhoto.Avatar.make, {
          alt: "A TAN user",
          className: avatarStyle
        });
  }
  var tmp$1;
  switch (source.__typename) {
    case "AgentMatchLogNotification" :
        var agentMatchLogByType = source.agentMatchLogByType;
        if (agentMatchLogByType !== undefined) {
          switch (agentMatchLogByType.__typename) {
            case "BuyerNeedAgentMatchLogV5" :
                tmp$1 = JsxRuntime.jsx(NotificationWidgetTexts.BuyerNeedMatchText.make, {
                      aml: agentMatchLogByType.fragmentRefs
                    });
                break;
            case "ForSalePropertyAgentMatchLogV5" :
                tmp$1 = JsxRuntime.jsx(NotificationWidgetTexts.PropertyMatchText.make, {
                      aml: agentMatchLogByType.fragmentRefs
                    });
                break;
            case "RentalNeedAgentMatchLogV5" :
                tmp$1 = JsxRuntime.jsx(NotificationWidgetTexts.RentalNeedMatchText.make, {
                      aml: agentMatchLogByType.fragmentRefs
                    });
                break;
            case "RentalPropertyAgentMatchLogV5" :
                tmp$1 = JsxRuntime.jsx(NotificationWidgetTexts.RentalPropertyMatchText.make, {
                      aml: agentMatchLogByType.fragmentRefs
                    });
                break;
            case "__unselected" :
                tmp$1 = null;
                break;
            
          }
        } else {
          tmp$1 = null;
        }
        break;
    case "UnfinishedDraftNotification" :
        var match$12 = source.draft;
        var match$13 = match$12.draftProperty;
        if (match$13 !== undefined) {
          var origin = match$12.origin;
          var address = match$13.address;
          var match$14 = Core__Option.map(match$13.userSelectedArea, (function (v) {
                  return v.name;
                }));
          var text = address !== undefined ? (
              match$14 !== undefined ? address + (", " + match$14) : address
            ) : (
              match$14 !== undefined ? match$14 : "(no address entered)"
            );
          if (origin !== undefined && (origin === "NEW" || origin === "BROADCAST_QUIET_SELLER" || origin === "REBROADCAST")) {
            switch (origin) {
              case "BROADCAST_QUIET_SELLER" :
                  tmp$1 = "Your draft for broadcasting your quiet seller at " + text + " is unfinished";
                  break;
              case "NEW" :
                  tmp$1 = "Your draft " + text + " is unfinished";
                  break;
              case "REBROADCAST" :
                  tmp$1 = "Your draft for rebroadcasting " + text + " is unfinished";
                  break;
              
            }
          } else {
            tmp$1 = "";
          }
        } else {
          tmp$1 = null;
        }
        break;
    case "UpcomingShowingNotification" :
        tmp$1 = JsxRuntime.jsx(NotificationWidgetTexts.ShowingNotification.make, {
              showing: source.showing.fragmentRefs
            });
        break;
    case "__unselected" :
        tmp$1 = null;
        break;
    
  }
  var tmp$2;
  switch (source.__typename) {
    case "AgentMatchLogNotification" :
        tmp$2 = "MATCH";
        break;
    case "UnfinishedDraftNotification" :
        tmp$2 = "DRAFT";
        break;
    case "UpcomingShowingNotification" :
        tmp$2 = "SHOWING";
        break;
    case "__unselected" :
        tmp$2 = "";
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: ""
                    }),
                JsxRuntime.jsxs(Link, {
                      href: link,
                      className: "grow text-zinc-600 hover:text-zinc-600",
                      children: [
                        JsxRuntime.jsx("div", {
                              children: tmp$1,
                              className: notification.status === "READ" ? "font-normal" : "font-semibold"
                            }),
                        JsxRuntime.jsx("div", {
                              children: tmp$2,
                              className: TailwindMerge.twMerge([
                                    "underline decoration-dashed decoration-zinc-300",
                                    notification.status === "READ" ? "font-normal" : "font-bold"
                                  ])
                            })
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Next__Atoms.SecondaryButton.make, {
                            href: link,
                            onClick: onClick,
                            size: "small",
                            className: "z-10",
                            children: Caml_option.some(match[0])
                          }),
                      className: "hidden md:block"
                    })
              ],
              className: TailwindMerge.twMerge([
                    "flex flex-row items-center space-x-4 py-4 px-2 border-b border-gray-200 relative",
                    notification.status === "READ" ? "" : "bg-gray-50"
                  ])
            });
}

var make = NotificationWidgetItem;

exports.NotificationFragment = NotificationFragment;
exports.NotificationSourceFragment = NotificationSourceFragment;
exports.avatarStyle = avatarStyle;
exports.make = make;
/* next/link Not a pure module */
