// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var AnimatedTooltip = require("./AnimatedTooltip");
var JsxRuntime = require("react/jsx-runtime");

var make = AnimatedTooltip.AnimatedTooltip;

var defaultStyle = Css.style(Core__List.map({
          hd: Css.backgroundColor(Css.rgba(0, 0, 0, {
                    NAME: "num",
                    VAL: 0.75
                  })),
          tl: {
            hd: Css.color({
                  NAME: "rgb",
                  VAL: [
                    255,
                    255,
                    255
                  ]
                }),
            tl: {
              hd: Css.borderStyle("none"),
              tl: {
                hd: Css.borderRadius({
                      NAME: "px",
                      VAL: 5
                    }),
                tl: {
                  hd: Css.paddingLeft({
                        NAME: "px",
                        VAL: 10
                      }),
                  tl: {
                    hd: Css.paddingRight({
                          NAME: "px",
                          VAL: 10
                        }),
                    tl: {
                      hd: Css.paddingTop({
                            NAME: "px",
                            VAL: 5
                          }),
                      tl: {
                        hd: Css.paddingBottom({
                              NAME: "px",
                              VAL: 5
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }, Css.important));

var Imported = {
  make: make,
  defaultStyle: defaultStyle
};

function Tooltip(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : defaultStyle;
  return JsxRuntime.jsx(make, {
              ariaLabel: props.ariaLabel,
              style: props.style,
              className: className,
              id: props.id,
              label: props.label,
              children: props.children
            });
}

var make$1 = Tooltip;

exports.Imported = Imported;
exports.make = make$1;
/* make Not a pure module */
