// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Loader = require("../../../uikit/reason/atoms/Loader/Loader.bs.js");
var MainLayout = require("../components/MainLayout.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CurrentUser = require("./CurrentUser.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var CurrentUser__FetchErrorMessage = require("./CurrentUser__FetchErrorMessage.bs.js");

var context = React.createContext(undefined);

var make = context.Provider;

var Provider = {
  make: make
};

function LoggedInAgent$Provider(props) {
  var __loaderComponent = props.loaderComponent;
  var loaderComponent = __loaderComponent !== undefined ? Caml_option.valFromOption(__loaderComponent) : JsxRuntime.jsx(Loader.make, {});
  var currentUser = CurrentUser.useCurrentSession();
  if (typeof currentUser === "object") {
    if (currentUser.TAG === "Agent") {
      return JsxRuntime.jsx(make, {
                  value: currentUser._0,
                  children: props.children
                });
    } else {
      return JsxRuntime.jsx(MainLayout.make, {
                  children: JsxRuntime.jsx(CurrentUser__FetchErrorMessage.make, {})
                });
    }
  }
  switch (currentUser) {
    case "NotCalled" :
    case "Loading" :
        return loaderComponent;
    default:
      return JsxRuntime.jsx(MainLayout.make, {
                  children: JsxRuntime.jsx(CurrentUser__FetchErrorMessage.make, {})
                });
  }
}

var Provider$1 = {
  Provider: Provider,
  make: LoggedInAgent$Provider
};

function useLoggedInAgent() {
  var value = React.useContext(context);
  return Core__Option.getExn(value, undefined);
}

var provider = LoggedInAgent$Provider;

exports.context = context;
exports.Provider = Provider$1;
exports.provider = provider;
exports.useLoggedInAgent = useLoggedInAgent;
/* context Not a pure module */
